.article-body {
  max-width: 660px;
  margin: 100px auto 0 auto;
  color: #fff;
  line-height: 1.6rem;
}

.article-ad {
  background-color: #404040;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2.8rem;

  &:hover {
    background-color: #545454;
  }
}

a.article-ad {
  color: #ffffff;
}

.article-ad img {
  width: 100%;
}
