html {
  scroll-behavior: smooth;
}

$primary-color: #eb5000;
$primary5-color: #fff7ec;
$primary10-color: #ffedd3;
$primary20-color: #ffd7a5;
$primary30-color: #ffba6d;
$primary40-color: #ff9132;
$primary50-color: #ff710a;
$primary60-color: #eb5000;
$primary70-color: #cc3d02;
$primary80-color: #a1300b;
$separation-color: #dfdfdf;
$bg-color: #17191a;
$bg-light-color: #252527;
$bg-light-color-hover: #404040;
$font-color: rgb(194, 194, 194);
$font-color-white: #f7f8f8;
$font-color-dark: #2d2d2d;
$footer-bg-color: #1d1d1d;
$footer-font-color: #fdfdfd;
$light-font-color: #fdfdfd;
$form-border-color: #cccccc;

$lesson-done-color: #30664b;
$lesson-selected-color: #999;
$lesson-hover-color: #1b8051;

$color-secondary: #877032;

$alert-bg-color: #fee4e2;
$alert-text-color: lightcoral;
$alert-button-color: #d92d20;

$radius-small: 8px;

// using https://codepen.io/sosuke/pen/Pjoqqp to convert colors:
$primary-color-filter: invert(17%) sepia(71%) saturate(3827%) hue-rotate(248deg)
  brightness(91%) contrast(137%);
$white-color-filter: invert(100%) sepia(0%) saturate(6757%) hue-rotate(258deg)
  brightness(121%) contrast(105%);
$alert-color-filter: invert(26%) sepia(71%) saturate(3821%) hue-rotate(349deg)
  brightness(87%) contrast(95%);

$button-color-purple: #6150ec;
$button-color-blue: #0e81fa;
$button-color-green: #49cf68;
$button-color-yellow: #f3ad26;

body {
  color: $font-color;
  background-color: $bg-color;

  margin: 0;
  padding: 0;

  line-height: 130%;

  overflow-x: hidden;
}

a {
  color: $primary-color;
  text-decoration: none;
  &:visited {
    color: $primary-color;
    text-decoration: none;
  }
}

@mixin text-main() {
  color: $font-color;
}

@mixin text-light() {
  color: $light-font-color;
}

@mixin text-dark() {
  color: $font-color-dark;
}

///*----------------------------------*\
//  #GLOBAL
//\*----------------------------------*/

// High-level base settings.
$base-font-size: 16px !default;
$base-line-height: 24px !default;
$base-text-color: #333 !default;
$base-background-color: #fff !default;

// These variables are framework variables, sourced from variables defined
// above. Feel free to use these variables throughout your project, but do not
// modify or reassign them.
$base-spacing-unit: $base-line-height;
$base-spacing-unit-tiny: round(calc($base-spacing-unit / 4));
$base-spacing-unit-small: round(calc($base-spacing-unit / 2));
$base-spacing-unit-large: round($base-spacing-unit * 2);
$base-spacing-unit-huge: round($base-spacing-unit * 4);

@mixin clearfix($important: false) {
  @if ($important == "important") {
    $important: unquote("!important");
  } @else {
    $important: null;
  }

  &:after {
    content: "" $important;
    display: table $important;
    clear: both $important;
  }
}

// Simple truncation mixin to cut off text using an ellipsis after a certain
// width.
//
// .simple-usage {
//   @include truncate();
// }
//
// .detailed-usage {
//   @include truncate(<value>, important);
// }

@mixin truncate($limit: 100%, $important: false) {
  @if ($important == "important") {
    $important: unquote("!important");
  } @else {
    $important: null;
  }

  max-width: $limit $important;
  white-space: nowrap $important;
  overflow: hidden $important;
  text-overflow: ellipsis $important;
}

.spaced {
  display: flex;
  justify-content: space-between;
}

.blue {
  background-color: $primary60-color;
}

.alt-blue {
  background-color: #0e81fa;
}

.green {
  background-color: #49cf68;
}

.orange {
  background-color: #f3ad26;
}

.badge-yellow {
  color: #b54708;
  background-color: #fffaeb;
}

.badge-red {
  color: #b42318;
  background-color: #fef3f2;
}

.badge-purple {
  color: #9518b4;
  background-color: #f8e1fe;
}

.badge-blue {
  color: #1878b4;
  background-color: #d7ddfe;
}

.badge-green {
  color: #18b41b;
  background-color: #dffde2;
}

.cols {
  display: flex;
  flex-direction: row;
  align-items: top;
  gap: 80px;

  @include sm {
    flex-direction: column-reverse;
  }
}

.cols > * {
  flex: 1;
}

.load-screen {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}

.load-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.load-msg {
  margin-top: 24px;
}
