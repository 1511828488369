.header {
  width: calc(100% - (2 * $gutter));
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: $gutter;
  padding-right: $gutter;

  @include sm {
    width: calc(100% - (2 * $mobile-gutter));
    flex-wrap: wrap;
    height: 140px;
    padding-left: $mobile-gutter;
    padding-right: $mobile-gutter;
  }
}

.header-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 50%;

  @include sm {
    width: 100%;
  }
}

.header-nav a {
  @include text-light();
  font-weight: bold;
  text-decoration: none;
}
