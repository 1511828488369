@mixin shadow1() {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0.5px 1px rgba(0, 0, 0, 0.14);
}

@mixin shadow2() {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 1px 2px rgba(0, 0, 0, 0.14);
}

@mixin shadow4() {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.14);
}

@mixin shadow8() {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 4px 8px rgba(0, 0, 0, 0.14);
}

@mixin shadow16() {
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.14),
    0px 8px 16px rgba(0, 0, 0, 0.14);
}

@mixin shadow28() {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2), 0px 14px 28px rgba(0, 0, 0, 0.24);
}

@mixin shadow64() {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2), 0px 32px 64px rgba(0, 0, 0, 0.24);
}
