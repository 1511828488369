.flex-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-table-header {
  display: flex;
  flex-direction: row;

  font-weight: bold;
  color: white;
}

.flex-cell {
  width: 20%;
  padding: 8px 8px 8px 0;
}

.flex-cell-narrow {
  width: 10%;
  padding: 8px 8px 8px 0;
}

.flex-table-row {
  display: flex;
  flex-direction: row;
}
