.footer {
  min-height: 80vh;
  background-color: black;
  margin-top: 100px;
  padding-top: 100px;

  @include sm {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

.footer-cols {
  display: flex;
  flex-direction: row;

  @include sm {
    flex-direction: column;
  }
}

.footer-cols > * {
  flex: 1;
}

.footer-bio {
  display: flex;
  flex-direction: row;
  gap: 48px;

  @include sm {
    gap: 24px;
    margin-top: 48px;
  }
}

.footer-bio-image img {
  width: 205px;

  @include sm {
    width: 80px;
  }
}

.footer-bio-description button {
  width: auto;
}

.insta-button {
  width: 44px;
  height: 44px;
  background-color: $primary-color;
  display: grid;
  place-items: center;
  border-radius: 8px;
}

.insta-button img {
  width: 32px;
  height: 32px;
}
