$gutter: 2rem;
$mobile-gutter: 1rem;

.wrapper {
  width: 100%;
  max-width: 1728px;
  margin: 0 auto 0 auto;

  @include font-body();
}

.wrapper-inner {
  margin: 0 $gutter 0 $gutter;

  @include sm {
    margin: 0 $mobile-gutter 0 $mobile-gutter;
  }
}
