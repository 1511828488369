form {
  @include font-body();
}

.form-header {
  margin-bottom: 24px;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0 0 24px 0;
}

.large-label {
  padding: 0;
  margin: 0 0 6px 0;
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
}

label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: block;
  margin-bottom: 6px;
}

.form-default {
  margin-top: 40px;
}

input {
  padding: 12px;
  margin: 0 0 0px 0;
  background-color: white;
  font-family: inherit;
  border: 1px solid $form-border-color;
  border-radius: 6px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

input[type="button"],
input[type="submit"] {
  appearance: button;
  backface-visibility: hidden;
  background-color: $primary-color;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;

  font-family: $body-font -apple-system, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", Ubuntu, sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;

  &:hover {
    background-color: $font-color;
  }
}

textarea {
  width: 100%;
  height: 300px;

  border: 1px solid $form-border-color;
  border-radius: 6px;
  box-sizing: border-box;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select {
  padding: 12px;
  margin: 0 0 20px 0;
  background-color: white;
  font-family: inherit;
  border: 1px solid #ccc;
  border-radius: 6px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;

  background: transparent;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.form-alert-danger {
  background-color: $alert-bg-color;
  border: 1px solid $alert-text-color;
  border-radius: 10px;
  @include font-body();
  padding-left: 10px;
}

.form-alert-success {
  background-color: rgb(134, 232, 193);
  border: 1px solid rgb(69, 219, 99);
  border-radius: 10px;
  @include font-body();
  padding-left: 10px;
}

.add-colorscheme-form {
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 20px;
}

.form-error {
  margin: 0 0 20px 0;
  padding: 0;
  color: #bf1650;
}

.form-error::before {
  display: inline;
  content: "⚠ ";
}

.inline-toast-medium {
  margin-top: 32px;
  border-radius: 10px;
  @include font-body();
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 16px;
}

.inline-toast-small {
  margin-top: 6px;
  border-radius: 10px;
  @include font-body();
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  color: #bf1650;
}

.error-div-medium {
  background-color: #fee4e2;
  border: 1px solid #fda29b;
}

.error-div-small {
  background-color: white;
}

.success-div {
  background-color: #ecfdf3;
  border: 1px solid #b9f4d2;
}

.toast-icon-medium {
  width: 32px;
}

.toast-icon-small {
  width: 24px;
}

.toast-message {
  margin: auto 0 auto 0;
  color: #000;
}

.error-icon {
  filter: invert(19%) sepia(46%) saturate(5521%) hue-rotate(325deg)
    brightness(81%) contrast(97%);
}

.success-icon {
  filter: invert(53%) sepia(95%) saturate(744%) hue-rotate(71deg)
    brightness(88%) contrast(83%);
}

.after-form {
  font-size: 0.8em;
  margin-top: 24px;
}

select {
  color: #fff;
}
