.video-container {
  padding: 56.25% 0 0 0;
  position: relative;
}

.video-iframe-default {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
