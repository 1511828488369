.enrollments {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.toggle-button {
  padding: 10px 20px;
  background-color: $bg-color;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button.active {
  background-color: #4caf50; /* Or any other active color */
  color: #ffffff;
}
