// Variables
$hero-color: rgb(31, 31, 31);
$speed: "0.45s";
$transition: all #{$speed} cubic-bezier(0.31, -0.105, 0.43, 1.4);

button {
  appearance: button;
  backface-visibility: hidden;
  background-color: $primary-color;
  border-radius: 6px;
  border-width: 0;
  //box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
  //  rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all 0.2s, box-shadow 0.08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.button-standard {
  width: auto;
}

.button-row {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.button-wide {
  width: auto;
  padding: 0 40px;
}

button:disabled {
  cursor: default;
  background-color: #ddd;
}

button:focus {
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

button:hover {
  background-color: $primary80-color;
}

button.light {
  background-color: white;
  border: 1px solid $primary-color;
  color: $primary-color;
}

button.light-grey {
  background-color: white;
  border: 1px solid $form-border-color;
  color: $font-color;
}

button.alert {
  background-color: $alert-button-color;
  border: 1px solid $alert-button-color;
  color: white;
}

a.button {
  text-decoration: none;
}

.back-button {
  background-color: white;
  border: 1px solid $form-border-color;
  color: $font-color;
  width: auto;
  padding: 0 24px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: white;
  }

  &:hover img {
    filter: $white-color-filter;
  }
}

.back-button-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: -20px;
}

.button {
  @include font-body();
  display: block;
  background-color: $primary-color;
  height: 48px;
  line-height: 48px;
  margin: auto;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 16px;
  transition: $transition;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;

  span {
    display: block;
    color: #fff;
    margin: 0 30px 0 30px;
    overflow: hidden;
    line-height: inherit;
    font-size: 18px;
    font-weight: bold;
    left: 0;
  }

  &:hover {
    background-color: black;
  }
}

a.hero-button {
  text-decoration: none;
}

.hero-button {
  @include font-body();
  display: block;
  background-color: $hero-color;
  height: 64px;
  line-height: 64px;
  margin: auto;
  outline: none;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  border-radius: 16px;
  transition: $transition;
  display: flex;
  flex-wrap: nowrap;

  box-shadow: rgba(0, 0, 0, 0.004) 0px 0.783403px 0.783403px 0px,
    rgba(0, 0, 0, 0.01) 0px 1.92107px 1.92107px 0px,
    rgba(0, 0, 0, 0.024) 0px 3.63429px 3.63429px 0px,
    rgba(0, 0, 0, 0.04) 0px 6.35486px 6.35486px 0px,
    rgba(0, 0, 0, 0.07) 0px 11.0474px 11.0474px 0px,
    rgba(0, 0, 0, 0.125) 0px 20.2497px 20.2497px 0px,
    rgba(0, 0, 0, 0.25) 0px 40px 40px 0px;

  span,
  .icon,
  .icon2 {
    display: block;
    height: 100%;
  }

  span {
    flex: 0 1 calc(100% - 20px);
    width: calc(100% - 20px);
    overflow: hidden;
    line-height: inherit;
    font-size: 18px;
    font-weight: bold;
    left: 0;
    transition: $transition;
    transform: translateX(10%);
  }

  .icon {
    flex: 0 1 60px;
    width: 60px;
    transition: $transition;
    transform: translateX(40%);
  }

  .icon2 {
    flex: 0 1 40px;
    width: 40px;
    transition: $transition;
    transform: translateX(100%);
  }

  .icon img {
    margin-top: 18px;
  }

  .icon2 img {
    margin-top: 18px;
  }

  .button-logo {
    filter: invert(99%) sepia(23%) saturate(971%) hue-rotate(198deg)
      brightness(112%) contrast(100%);
    width: 28px;
  }

  .button-more {
    filter: invert(99%) sepia(23%) saturate(971%) hue-rotate(198deg)
      brightness(112%) contrast(100%);
    width: 28px;
  }

  &:hover {
    span {
      transform: translateX(-26%);
      transition-delay: 0.1s;
    }
    .icon {
      transform: translateX(-100%);
    }
    .icon2 {
      transform: translateX(-80%);
      transition-delay: 0.2s;
    }
  }
}

.loading-button {
  display: grid;
  place-items: center;

  &:disabled {
    background-color: $primary-color;
  }

  &:hover {
    background-color: $primary-color;
  }
}
