.course-cards {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  min-height: 200px;
  margin-bottom: 60px;
}

a > .course-card {
  text-decoration: none;
}

.course-card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
  height: 300px;
  padding: 24px;
  border-radius: 8px;
  @include shadow2();
  background-color: $bg-light-color;

  @include sm {
    width: auto;
    height: 260px;
  }
}

.course-card > h3 {
  font-size: 1.4rem;
}

.course-card-image img {
  width: 100%;
}

.course-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;
  gap: 60px;
  margin-top: 40px;

  @include sm {
    display: flex;
    flex-direction: column-reverse;
  }
}

.lesson {
  min-height: 60vh;
}

.sidebar {
  width: 400px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;

  @include sm {
    width: 100%;
    min-width: 100%;
  }
}

.sidebar > h2 {
  margin-bottom: 24px;
}

.lesson-button {
  background-color: $bg-light-color;
  height: 64px;
  padding: 0 12px 0 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: $bg-light-color-hover;
  }

  @include lt-lg {
    height: 64px;
    padding-left: 12px;
    margin-bottom: 12px;
  }
}

.lesson-button-check {
  width: 28px;
  min-width: 28px;
  height: 28px;
  background-color: white;
  border: 2px solid $font-color;
  border-radius: 28px;

  display: grid;
  place-items: center;

  @include sm {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
}

.lesson-button-check img {
  width: 20px;
  height: 20px;

  @include sm {
    width: 16px;
    height: 16px;
  }
}

.completed {
  background-color: $lesson-done-color;
  @include text-light();

  &:hover {
    background-color: $lesson-hover-color;
  }
}

.main {
  width: 100%;
  line-height: $base-line-height;
  @include text-md();
}

.main img {
  max-width: 100%;
}

.lesson-status {
  background-color: $bg-light-color;
  border-radius: 12px;
  padding: 0 48px 0 48px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lesson-status-complete {
  background-color: $lesson-done-color;
  @include text-light();
}

.lesson-done-button {
  background-color: $color-secondary;
  width: auto;
}

.lesson-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
}

.lesson-selected {
  background-color: $lesson-selected-color;
  @include text-dark();

  &:hover {
    background-color: $lesson-selected-color;
  }
}

.task {
  background-color: linear-gradient(
    to left bottom,
    rgb(79, 93, 95) 40%,
    rgb(51, 62, 88)
  );
  background-color: #445253;
  border-radius: 12px;
  margin: 48px 0 48px 0;
  padding: 48px;
  color: $font-color-white;
}

.task h2 {
  color: #7ecdbf;
}
