.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-window {
  position: relative;
  background-color: white;
  padding: 24px;
  border-radius: 12px;
}

.modal-window-md {
  max-width: 500px;
  width: 90%;
}

.modal-window-sm {
  max-width: 400px;
  width: 90%;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.modal-content p {
  margin: 10px 0 0 0;
  padding: 0;
}

.modal-content button {
  margin-top: 16px;
}

.modal-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.modal-close-icon {
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-top: 6px;
}
