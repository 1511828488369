.avatar {
  background-color: lightblue;
  border-radius: 500px;
  color: #fff;
  display: grid;
  place-items: center;
}

.avatar-xlarge {
  width: 160px;
  height: 160px;
  border: 4px solid #fff;
  font-size: 3rem;
  font-weight: 800;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
.avatar-large {
  width: 96px;
  height: 96px;
  border: 3px solid #fff;
  font-size: 2rem;
  font-weight: 800;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.avatar-medium {
  width: 68px;
  height: 68px;
  font-size: 1.4rem;
  font-weight: 600;
}

.avatar-small {
  width: 48px;
  height: 48px;
  font-size: 1.1rem;
  font-weight: 600;
}

.avatar-xsmall {
  width: 40px;
  height: 40px;
  font-size: 0.9rem;
  font-weight: 600;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
