.hero-section {
  width: 100%;
  max-width: 100%;
  min-height: 600px;

  @include sm {
    min-height: 100px;
  }
}

.hero-image-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.hero-image-spacer {
  width: 25%;

  @include sm {
    display: none;
    visibility: hidden;
  }
}

.hero-image {
  width: 100%;
}

.hero-image-gradient {
  width: 75%;
  position: relative;
  display: inline-block;

  @include sm {
    width: 100%;
  }
}

.hero-image-gradient:after {
  @include gt-sm {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: -moz-linear-gradient(
      right,
      rgba(23, 25, 26, 0) 0%,
      rgba(23, 25, 26, 1) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left right,
      left left,
      color-stop(0%, rgba(23, 25, 26, 1)),
      color-stop(100%, rgba(23, 25, 26, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      right,
      rgba(23, 25, 26, 0) 0%,
      rgba(23, 25, 26, 1) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      right,
      rgba(23, 25, 26, 0) 0%,
      rgba(23, 25, 26, 1) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      right,
      rgba(23, 25, 26, 0) 0%,
      rgba(23, 25, 26, 1) 100%
    ); /* IE10+ */
    background: linear-gradient(
      to left,
      rgba(23, 25, 26, 0) 0%,
      rgba(23, 25, 26, 1) 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#17191a', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }
}

.hero-image-gradient img {
  display: block;
}

.course-header {
  width: 50%;
  margin-left: $gutter;
  margin-top: -580px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  @include text-light();

  @include lt-xl {
    margin-top: calc(-580px * (100vw / 1728px));
  }

  @include sm {
    margin-top: 20px;
    margin-left: $mobile-gutter;
    margin-right: $mobile-gutter;
    width: calc(100% - (2 * $mobile-gutter));
  }
}

.course-header h1 {
  font-size: 4.8rem;

  @include lg {
    font-size: 4rem;
  }

  @include md {
    font-size: 3rem;
  }

  @include sm {
    font-size: $mobile-heading-size-1;
  }
}

.price-info {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 1.4rem;
}

.price-info .price {
  font-weight: 700;
}

.hero-register {
  margin: 20px 0 0 0;
}

.course-description {
  margin-top: 200px;

  @include lg {
    margin-top: 20px;
  }

  @include md {
    margin-top: -40px;
  }

  @include sm {
    margin-top: 60px;
  }
}

.course-description > .overview > h2 {
  font-size: 2.8rem;

  @include lg {
    font-size: 2.2rem;
  }

  @include md {
    font-size: 1.8rem;
  }

  @include sm {
    font-size: 1.4rem;
  }
}
