$landing-gutter: 32px;

.landing-body {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  color: #333;
  padding-top: 32px;
  padding-bottom: 32px;
  overflow-x: hidden;
}

.landing-wrapper {
  margin: 0 auto 0 auto;
  max-width: 430px;
  overflow-x: hidden;
}

.landing-hero {
  background: url("http://learning.bechbox.com/images/landing01/generativ_ai_landing_hero01.jpg")
    no-repeat top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 24px;
  padding: 32px;
  height: 462px;
  max-width: 366px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: $landing-gutter;
  margin-right: $landing-gutter;
}

.landing-hero-top > h1 {
  font-size: 32px;
  color: white;
  margin-top: 6px;
}

.landing-hero-pre {
  margin-top: 50px;
  color: white;
  font-size: 13px;
  text-transform: uppercase;
}

.landing-hero-bottom {
  background-color: white;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3))
    drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));

  border-radius: 18px;
  color: #2d2d2d;
  height: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.landing-hero-bottom > p {
  padding: 0 24px 0 24px;
  text-align: center;
  margin: 0;
  @include font-header();
}

.landing-button {
  background-color: #bd639f;
  border-radius: 8px;
  height: 32px;
  color: white;
  margin: 0;
  display: grid;
  place-items: center;
  padding: 0 18px 0 18px;
  width: fit-content;

  &:hover {
    background-color: #2d2d2d;
  }
}

a.landing-button {
  color: white;
}

.landing-section {
  margin-top: 32px;
  margin-left: $landing-gutter;
  margin-right: $landing-gutter;
}

.landing-section > h3 {
  color: #2d2d2d;
}

.landing-2cols {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.landing-2cols-col {
  max-width: 167px;
}

.landing-2cols-col > img {
  border-radius: 24px;
  overflow: hidden;
  width: 100%;
}

.staggered {
  margin-top: 64px;
}

.landing-dj {
  background-color: #f5efe6;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  height: 164px;
  padding: 0 24px 0 24px;
  gap: 24px;
  margin-top: 32px;
  margin-left: $landing-gutter;
  margin-right: $landing-gutter;
  font-size: 0.9em;
}

.landing-dj > img {
  width: 118px;
}

.landing-cta {
  margin-top: 32px;
  margin-left: $landing-gutter;
  margin-right: $landing-gutter;
  display: flex;
  flex-direction: column;
  align-items: center;
}
